import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedModule } from '../shared/shared.module';
import { GoogleMapComponent } from './google-map.component';

@NgModule({
  declarations: [GoogleMapComponent],
  imports: [CommonModule, GoogleMapsModule, SharedModule],
  exports: [GoogleMapComponent],
})
export class GoogleMapModule {}
